import Layout from "../components/Layout";
import Head from "next/head";
import Link from "next/link";

export default function Login({ }) {
    const login = async event => {
        event.preventDefault() // prevents page from redirecting on form submissiomn

        const { error, user } = await fetch("/api/login", {
            body: JSON.stringify({
                email: event.target.email.value,
                password: event.target.password.value,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        });
        if (error) {
            console.log(error)
        }
        if (user) {
            console.log(user)
        }
        
    }

    return (
        <form onSubmit={login}>
            <label htmlFor="email">Email</label>
            <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
            />
            <label htmlFor="password">Password</label>

            <input
                type="password"
                id="password"
                name="password"
                required
            />
            <button type="submit">Login</button>
        </form>
    )
}